body:has(dialog.touch-scroll) {
  overflow: hidden !important;
}

dialog {
  @extend %box-radius, %popup-shadow;
  position: fixed;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  @include inline-start(50%);
  transform: translate(-50%, -50%);
  @include if-rtl {
    transform: translate(50%, -50%);
  }
  padding: 0;
  border: none;
  outline: none;
  background: $c-bg-high;
  z-index: $z-modal-111;
  &:has(.dialog-content.alert) {
    z-index: $z-modal-alert-200;
  }

  &::backdrop {
    background: $c-page-mask;
  }

  > div.scrollable {
    max-height: calc(var(---viewport-height) - 16px);
    overflow-x: clip;
    overflow-y: auto;
  }

  div.close-button-anchor {
    position: relative;
    width: 100%;
    height: 0;
  }

  button.close-button {
    @extend %flex-around;

    position: absolute;
    top: 4px;
    @include inline-end(4px);
    width: 40px; // bigger for phones
    height: 40px;
    z-index: $z-modal-111 + 1;
    background: $c-bg-high;
    color: $c-font;
    border-radius: 6px;
    border: 1px solid $c-border;
    font-size: 20px;
    text-align: center;

    cursor: pointer;

    &:not(:focus) {
      outline: none;
    }
  }

  &:not(.touch-scroll) {
    margin-top: 16px;
    overflow: visible;

    button.close-button {
      transform: translate(18px, -18px);
      @include if-rtl {
        transform: translate(-18px, -18px);
      }
      width: 32px;
      height: 32px;
      font-size: 16px;
      border-radius: 50%;
      border: none;
      &:hover {
        @include box-shadow;
        background: $c-bad;
        color: #fff;
      }
    }
  }
}

// applied to dialog wrapper div so we can fake HTMLDialogElement.showModal() in a snab render loop
.snab-modal-mask {
  @extend %fullscreen-mask, %flex-center;
  justify-content: center;
}

.dialog-content {
  text-align: center;
  padding: 2em;
  color: $c-font;

  &.alert,
  &.debug {
    @extend %flex-column;
    gap: 2em;
    width: unset;
    height: unset;
    border-radius: 6px;
    border: 3px solid $c-primary;
  }
  &.alert {
    width: 480px;
    max-width: 100%;
    font-size: 16px;
    span {
      display: flex;
      justify-content: end;
      gap: 2em;
    }
    input {
      align-self: center;
      width: 40ch;
    }
    .invalid {
      background-color: $m-bad_bg--mix-30;
    }
  }
}
