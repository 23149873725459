/// Z-indexes, gathering all Z layers of the application

$z-cg__promotion-205: 205;
$z-cg__piece_dragging-204: 204;

$z-modal-alert-200: 200;
$z-powertip-120: 120;
$z-complete-113: 113;
$z-inquiry-112: 112;
$z-zen-toggle-111: 111;
$z-modal-111: 111;
$z-mselect-111: 111;
$z-topnav-111: 111;
$z-fullscreen-mask-110: 110;
$z-dropdown-109: 109;
$z-context-menu-108: 108;
$z-above-site-header-107: 107;
$z-site-header-106: 106;
$z-network-status-105: 105;
$z-tour-reminder-104: 104;
$z-video-player-controls-101: 101;
$z-video-player-100: 100;
$z-cg__board_overlay-100: 100;
$z-above-dialog-14: 14;
$z-above-dialog-13: 13;
$z-above-dialog-12: 12;
$z-dialog-11: 11;
$z-cg__board_resize-10: 10;
$z-above-pieces-9: 9;
$z-cg__svg_cg-custom-svgs-4: 4;
$z-mz-menu-4: 4;
$z-above-link-overlay-3: 3;
$z-cg__piece_anim-3: 3;
$z-cg__svg_cg-shapes-2: 2;
$z-cg__cg-auto-pieces-2: 2;
$z-cg__piece-2: 2;
$z-friend-box-2: 2;
$z-link-overlay-2: 2;
$z-game-bookmark-2: 2;
$z-subnav-side-2: 2;
$z-cg__piece_fading-1: 1;
$z-default-0: 0;
