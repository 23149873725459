$c-accent: var(--c-accent);
$c-accent-clear: var(--c-accent-clear);
$c-accent-dim: var(--c-accent-dim);
$c-accent-faint: var(--c-accent-faint);
$c-bad: var(--c-bad);
$c-bg: var(--c-bg);
$c-bg-box: var(--c-bg-box);
$c-bg-header-dropdown: var(--c-bg-header-dropdown);
$c-bg-high: var(--c-bg-high);
$c-bg-input: var(--c-bg-input);
$c-bg-low: var(--c-bg-low);
$c-bg-page: var(--c-bg-page);
$c-bg-popup: var(--c-bg-popup);
$c-bg-variation: var(--c-bg-variation);
$c-bg-zebra: var(--c-bg-zebra);
$c-bg-zebra2: var(--c-bg-zebra2);
$c-blunder: var(--c-blunder);
$c-body-gradient: var(--c-body-gradient);
$c-border: var(--c-border);
$c-border-light: var(--c-border-light);
$c-border-page: var(--c-border-page);
$c-border-tour: var(--c-border-tour);
$c-bot: var(--c-bot);
$c-brag: var(--c-brag);
$c-brilliant: var(--c-brilliant);
$c-chat-host-bg: var(--c-chat-host-bg);
$c-chat-mention-bg: var(--c-chat-mention-bg);
$c-clas: var(--c-clas);
$c-clas-light: var(--c-clas-light);
$c-clearer: var(--c-clearer);
$c-contours: var(--c-contours);
$c-dark: var(--c-dark);
$c-dasher: var(--c-dasher);
$c-dasher-light: var(--c-dasher-light);
$c-dimmer: var(--c-dimmer);
$c-error: var(--c-error);
$c-fancy: var(--c-fancy);
$c-font: var(--c-font);
$c-font-clear: var(--c-font-clear);
$c-font-clearer: var(--c-font-clearer);
$c-font-dim: var(--c-font-dim);
$c-font-dimmer: var(--c-font-dimmer);
$c-font-page: var(--c-font-page);
$c-font-shadow: var(--c-font-shadow);
$c-good: var(--c-good);
$c-good-move: var(--c-good-move);
$c-header-dropdown: var(--c-header-dropdown);
$c-inaccuracy: var(--c-inaccuracy);
$c-interesting: var(--c-interesting);
$c-link: var(--c-link);
$c-link-clear: var(--c-link-clear);
$c-link-dim: var(--c-link-dim);
$c-link-hover: var(--c-link-hover);
$c-live: var(--c-live);
$c-marathon: var(--c-marathon);
$c-metal-bottom: var(--c-metal-bottom);
$c-metal-bottom-hover: var(--c-metal-bottom-hover);
$c-metal-top: var(--c-metal-top);
$c-metal-top-hover: var(--c-metal-top-hover);
$c-mistake: var(--c-mistake);
$c-over: var(--c-over);
$c-page-input: var(--c-page-input);
$c-page-mask: var(--c-page-mask);
$c-paper: var(--c-paper);
$c-pool-button: var(--c-pool-button);
$c-primary: var(--c-primary);
$c-primary-clear: var(--c-primary-clear);
$c-primary-dim: var(--c-primary-dim);
$c-racer-boost: var(--c-racer-boost);
$c-secondary: var(--c-secondary);
$c-secondary-dim: var(--c-secondary-dim);
$c-secondary-dimmer: var(--c-secondary-dimmer);
$c-shade: var(--c-shade);
$c-tutor-bg: var(--c-tutor-bg);
$c-warn: var(--c-warn);
$m-accent--fade-30: var(--m-accent--fade-30);
$m-accent--fade-40: var(--m-accent--fade-40);
$m-accent--fade-50: var(--m-accent--fade-50);
$m-accent--fade-70: var(--m-accent--fade-70);
$m-accent--fade-80: var(--m-accent--fade-80);
$m-accent--fade-85: var(--m-accent--fade-85);
$m-accent_bg--mix-10: var(--m-accent_bg--mix-10);
$m-accent_bg--mix-12: var(--m-accent_bg--mix-12);
$m-accent_bg--mix-15: var(--m-accent_bg--mix-15);
$m-accent_bg--mix-20: var(--m-accent_bg--mix-20);
$m-accent_bg--mix-60: var(--m-accent_bg--mix-60);
$m-accent_bg--mix-70: var(--m-accent_bg--mix-70);
$m-accent_bg-popup--mix-10: var(--m-accent_bg-popup--mix-10);
$m-accent_bg-zebra--mix-10: var(--m-accent_bg-zebra--mix-10);
$m-accent_black--mix-70: var(--m-accent_black--mix-70);
$m-accent_white--mix-80: var(--m-accent_white--mix-80);
$m-bad--alpha-50: var(--m-bad--alpha-50);
$m-bad--fade-40: var(--m-bad--fade-40);
$m-bad--fade-80: var(--m-bad--fade-80);
$m-bad--lighten-11: var(--m-bad--lighten-11);
$m-bad_bg--mix-10: var(--m-bad_bg--mix-10);
$m-bad_bg--mix-15: var(--m-bad_bg--mix-15);
$m-bad_bg--mix-20: var(--m-bad_bg--mix-20);
$m-bad_bg--mix-25: var(--m-bad_bg--mix-25);
$m-bad_bg--mix-30: var(--m-bad_bg--mix-30);
$m-bad_bg--mix-50: var(--m-bad_bg--mix-50);
$m-bad_bg--mix-60: var(--m-bad_bg--mix-60);
$m-bad_bg--mix-80: var(--m-bad_bg--mix-80);
$m-bad_bg-zebra--mix-20: var(--m-bad_bg-zebra--mix-20);
$m-bad_bg-zebra2--mix-20: var(--m-bad_bg-zebra2--mix-20);
$m-bad_bg-zebra2--mix-30: var(--m-bad_bg-zebra2--mix-30);
$m-bad_bg-zebra2--mix-60: var(--m-bad_bg-zebra2--mix-60);
$m-bad_clearer--mix-80: var(--m-bad_clearer--mix-80);
$m-bad_dark--mix-80: var(--m-bad_dark--mix-80);
$m-bg--alpha-40: var(--m-bg--alpha-40);
$m-bg--fade-20: var(--m-bg--fade-20);
$m-bg--fade-30: var(--m-bg--fade-30);
$m-bg--fade-40: var(--m-bg--fade-40);
$m-bg--fade-50: var(--m-bg--fade-50);
$m-bg-page--fade-40: var(--m-bg-page--fade-40);
$m-bg-page_bg--mix-50: var(--m-bg-page_bg--mix-50);
$m-bg-zebra2--fade-1: var(--m-bg-zebra2--fade-1);
$m-bg_bad--mix-80: var(--m-bg_bad--mix-80);
$m-bg_high--lighten-11: var(--m-bg_high--lighten-11);
$m-blunder_bg--mix-25: var(--m-blunder_bg--mix-25);
$m-blunder_bg--mix-30: var(--m-blunder_bg--mix-30);
$m-blunder_bg--mix-60: var(--m-blunder_bg--mix-60);
$m-blunder_font--mix-70: var(--m-blunder_font--mix-70);
$m-body-gradient_bg-page--mix-50: var(--m-body-gradient_bg-page--mix-50);
$m-brag--alpha-70: var(--m-brag--alpha-70);
$m-brag--fade-50: var(--m-brag--fade-50);
$m-brag_bg--mix-15: var(--m-brag_bg--mix-15);
$m-brag_bg--mix-20: var(--m-brag_bg--mix-20);
$m-brag_bg--mix-40: var(--m-brag_bg--mix-40);
$m-brag_bg--mix-60: var(--m-brag_bg--mix-60);
$m-brag_bg--mix-70: var(--m-brag_bg--mix-70);
$m-brag_bg--mix-80: var(--m-brag_bg--mix-80);
$m-brag_bg-page--mix-20: var(--m-brag_bg-page--mix-20);
$m-brag_bg-page--mix-60: var(--m-brag_bg-page--mix-60);
$m-brag_dark--mix-85: var(--m-brag_dark--mix-85);
$m-brag_font--mix-60: var(--m-brag_font--mix-60);
$m-brag_white--mix-25: var(--m-brag_white--mix-25);
$m-brag_white--mix-80: var(--m-brag_white--mix-80);
$m-brilliant_bg--mix-25: var(--m-brilliant_bg--mix-25);
$m-brilliant_bg--mix-30: var(--m-brilliant_bg--mix-30);
$m-brilliant_bg--mix-60: var(--m-brilliant_bg--mix-60);
$m-clas--alpha-30: var(--m-clas--alpha-30);
$m-clas_bg--mix-10: var(--m-clas_bg--mix-10);
$m-clas_bg--mix-30: var(--m-clas_bg--mix-30);
$m-clas_bg--mix-7: var(--m-clas_bg--mix-7);
$m-clas_bg--mix-80: var(--m-clas_bg--mix-80);
$m-clas_bg-zebra--mix-20: var(--m-clas_bg-zebra--mix-20);
$m-clas_white--mix-20: var(--m-clas_white--mix-20);
$m-clas_white--mix-28: var(--m-clas_white--mix-28);
$m-clearer_bg-page--mix-70: var(--m-clearer_bg-page--mix-70);
$m-clearer_bg-page--mix-80: var(--m-clearer_bg-page--mix-80);
$m-clearer_body-gradient--mix-65: var(--m-clearer_body-gradient--mix-65);
$m-clearer_body-gradient--mix-75: var(--m-clearer_body-gradient--mix-75);
$m-dark--fade-40: var(--m-dark--fade-40);
$m-dimmer_clearer--mix-63: var(--m-dimmer_clearer--mix-63);
$m-dimmer_font--mix-60: var(--m-dimmer_font--mix-60);
$m-dimmer_font--mix-90: var(--m-dimmer_font--mix-90);
$m-font--fade-50: var(--m-font--fade-50);
$m-font--fade-70: var(--m-font--fade-70);
$m-font--fade-83: var(--m-font--fade-83);
$m-font_bg--mix-20: var(--m-font_bg--mix-20);
$m-font_bg--mix-30: var(--m-font_bg--mix-30);
$m-font_clas--mix-40: var(--m-font_clas--mix-40);
$m-font_dimmer--mix-50: var(--m-font_dimmer--mix-50);
$m-font_dimmer--mix-85: var(--m-font_dimmer--mix-85);
$m-font_white--mix-20: var(--m-font_white--mix-20);
$m-good_bg--mix-25: var(--m-good_bg--mix-25);
$m-good_bg--mix-30: var(--m-good_bg--mix-30);
$m-good_bg--mix-60: var(--m-good_bg--mix-60);
$m-inaccuracy_bg--mix-25: var(--m-inaccuracy_bg--mix-25);
$m-inaccuracy_bg--mix-30: var(--m-inaccuracy_bg--mix-30);
$m-inaccuracy_bg--mix-60: var(--m-inaccuracy_bg--mix-60);
$m-inaccuracy_font--mix-70: var(--m-inaccuracy_font--mix-70);
$m-interesting_bg--mix-25: var(--m-interesting_bg--mix-25);
$m-interesting_bg--mix-30: var(--m-interesting_bg--mix-30);
$m-interesting_bg--mix-60: var(--m-interesting_bg--mix-60);
$m-mistake_bg--mix-25: var(--m-mistake_bg--mix-25);
$m-mistake_bg--mix-30: var(--m-mistake_bg--mix-30);
$m-mistake_bg--mix-60: var(--m-mistake_bg--mix-60);
$m-mistake_font--mix-70: var(--m-mistake_font--mix-70);
$m-paper--fade-40: var(--m-paper--fade-40);
$m-paper_dimmer--mix-50: var(--m-paper_dimmer--mix-50);
$m-paper_dimmer--mix-75: var(--m-paper_dimmer--mix-75);
$m-primary--alpha-30: var(--m-primary--alpha-30);
$m-primary--alpha-70: var(--m-primary--alpha-70);
$m-primary--fade-50: var(--m-primary--fade-50);
$m-primary--fade-70: var(--m-primary--fade-70);
$m-primary--fade-80: var(--m-primary--fade-80);
$m-primary--lighten-10: var(--m-primary--lighten-10);
$m-primary--lighten-11: var(--m-primary--lighten-11);
$m-primary--lighten-7: var(--m-primary--lighten-7);
$m-primary_bg--mix-10: var(--m-primary_bg--mix-10);
$m-primary_bg--mix-12: var(--m-primary_bg--mix-12);
$m-primary_bg--mix-15: var(--m-primary_bg--mix-15);
$m-primary_bg--mix-17: var(--m-primary_bg--mix-17);
$m-primary_bg--mix-18: var(--m-primary_bg--mix-18);
$m-primary_bg--mix-20: var(--m-primary_bg--mix-20);
$m-primary_bg--mix-25: var(--m-primary_bg--mix-25);
$m-primary_bg--mix-30: var(--m-primary_bg--mix-30);
$m-primary_bg--mix-35: var(--m-primary_bg--mix-35);
$m-primary_bg--mix-40: var(--m-primary_bg--mix-40);
$m-primary_bg--mix-50: var(--m-primary_bg--mix-50);
$m-primary_bg--mix-60: var(--m-primary_bg--mix-60);
$m-primary_bg--mix-70: var(--m-primary_bg--mix-70);
$m-primary_bg--mix-75: var(--m-primary_bg--mix-75);
$m-primary_bg--mix-80: var(--m-primary_bg--mix-80);
$m-primary_bg-page--mix-15: var(--m-primary_bg-page--mix-15);
$m-primary_bg-page--mix-20: var(--m-primary_bg-page--mix-20);
$m-primary_bg-page--mix-60: var(--m-primary_bg-page--mix-60);
$m-primary_bg-page__mix-20--fade-70: var(--m-primary_bg-page__mix-20--fade-70);
$m-primary_bg-zebra--mix-10: var(--m-primary_bg-zebra--mix-10);
$m-primary_bg-zebra2--mix-20: var(--m-primary_bg-zebra2--mix-20);
$m-primary_black--mix-70: var(--m-primary_black--mix-70);
$m-primary_black--mix-80: var(--m-primary_black--mix-80);
$m-primary_dark--mix-80: var(--m-primary_dark--mix-80);
$m-primary_font--mix-35: var(--m-primary_font--mix-35);
$m-primary_font--mix-50: var(--m-primary_font--mix-50);
$m-primary_font--mix-90: var(--m-primary_font--mix-90);
$m-primary_white--mix-30: var(--m-primary_white--mix-30);
$m-primary_white--mix-40: var(--m-primary_white--mix-40);
$m-primary_white--mix-60: var(--m-primary_white--mix-60);
$m-primary_white--mix-80: var(--m-primary_white--mix-80);
$m-secondary--fade-30: var(--m-secondary--fade-30);
$m-secondary--fade-40: var(--m-secondary--fade-40);
$m-secondary--fade-50: var(--m-secondary--fade-50);
$m-secondary--fade-60: var(--m-secondary--fade-60);
$m-secondary--fade-80: var(--m-secondary--fade-80);
$m-secondary--lighten-11: var(--m-secondary--lighten-11);
$m-secondary--lighten-4: var(--m-secondary--lighten-4);
$m-secondary_bg--mix-10: var(--m-secondary_bg--mix-10);
$m-secondary_bg--mix-12: var(--m-secondary_bg--mix-12);
$m-secondary_bg--mix-15: var(--m-secondary_bg--mix-15);
$m-secondary_bg--mix-20: var(--m-secondary_bg--mix-20);
$m-secondary_bg--mix-25: var(--m-secondary_bg--mix-25);
$m-secondary_bg--mix-30: var(--m-secondary_bg--mix-30);
$m-secondary_bg--mix-35: var(--m-secondary_bg--mix-35);
$m-secondary_bg--mix-40: var(--m-secondary_bg--mix-40);
$m-secondary_bg--mix-50: var(--m-secondary_bg--mix-50);
$m-secondary_bg--mix-70: var(--m-secondary_bg--mix-70);
$m-secondary_bg--mix-75: var(--m-secondary_bg--mix-75);
$m-secondary_bg--mix-80: var(--m-secondary_bg--mix-80);
$m-secondary_bg-page--mix-50: var(--m-secondary_bg-page--mix-50);
$m-secondary_bg-popup--mix-10: var(--m-secondary_bg-popup--mix-10);
$m-secondary_bg-popup--mix-27: var(--m-secondary_bg-popup--mix-27);
$m-secondary_bg-popup--mix-36: var(--m-secondary_bg-popup--mix-36);
$m-secondary_bg-zebra--mix-12: var(--m-secondary_bg-zebra--mix-12);
$m-secondary_bg-zebra--mix-20: var(--m-secondary_bg-zebra--mix-20);
$m-secondary_dark--mix-50: var(--m-secondary_dark--mix-50);
$m-secondary_dark--mix-85: var(--m-secondary_dark--mix-85);
$m-secondary_dimmer--mix-82: var(--m-secondary_dimmer--mix-82);
$m-secondary_white--mix-80: var(--m-secondary_white--mix-80);
$m-shade--fade-40: var(--m-shade--fade-40);
$m-yellow_bg--mix-30: var(--m-yellow_bg--mix-30);
