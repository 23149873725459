@import 'boards';

@each $name, $board-theme in $board-themes-2d {
  body[data-board='#{$name}'] .is2d {
    cg-board {
      $file-ext: map-get($board-theme, file-ext);
      $name-override: map-get($board-theme, name-override);
      $file-name: if($name-override, $name-override, $name);
      $dir-name: 'board#{if($file-ext == "svg", "/svg", "")}';
      &::before {
        background-image: url(../images/#{$dir-name}/#{$file-name}.#{$file-ext});
      }
    }

    $coord-color-white: map-get($board-theme, coord-color-white);
    $coord-color-black: map-get($board-theme, coord-color-black);

    @if $coord-color-white and $coord-color-black {
      coords {
        ---cg-ccw: #{$coord-color-white};
        ---cg-ccb: #{$coord-color-black};
        ---cg-cs: none;
      }
    }
  }
}

.is2d cg-board::before {
  top: 0;
  height: 100%;
}

.is2d piece {
  left: 0;
  top: 0;
  width: 12.5%;
  height: 12.5%;
}
