.btn-rack {
  @extend %box-radius;
  @include prevent-select;

  display: inline-flex;
  align-items: center;
  border: $border;

  .btn-rack {
    /* embedded racks */
    border: none;
  }

  &__btn,
  form {
    @extend %flex-center-nowrap, %metal;

    justify-content: center;
    text-align: center;
    line-height: 2rem;
    cursor: pointer;
    border-inline-end: $border;
    color: $c-font;
    white-space: nowrap;

    &:hover {
      @extend %metal-hover;

      color: $c-font;
    }

    &:first-child {
      @extend %box-radius-left;
    }

    &:last-child {
      @extend %box-radius-right;

      border: 0;
    }

    i {
      @extend %roboto;

      letter-spacing: -1px;
    }
  }

  &__btn {
    padding: 0 0.6em;

    &.active {
      @extend %active;

      color: #fff;
    }
    &[disabled] {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}
