#powerTip,
#miniGame,
#miniBoard {
  @extend %box-radius-force, %popup-shadow;

  width: 21rem;
  min-height: 3em;
  background: $c-bg-popup;
  display: none;
  position: absolute;
  z-index: $z-powertip-120;

  .mini-game__player {
    @include padding-direction(3px, 0.5em, 0.3em, 0.7em);

    &:first-child {
      @include padding-direction(0.3em, 0.5em, 3px, 0.7em);
    }
  }
}

.upt {
  &__info {
    @extend %flex-column;

    height: 83px;
    body.no-rating & {
      height: unset;
    }
    padding: 0.4em 0.5em 0.3em 0.5em;
    border-bottom: $border;
    overflow: hidden;

    &__top {
      @extend %flex-between-nowrap, %ellipsis;
      gap: 0.6ch;

      .user-link {
        flex: 0 0 auto;
        display: block;
        font-size: 1.1em;
        .line {
          vertical-align: middle;
        }
      }
      &__flag {
        @extend %flex-center;
        margin-inline-start: 0.5ch;
        gap: 0.5ch;
        flex: 1 1 auto;
        overflow: hidden;
        font-size: 0.9em;
        color: $c-font-dim;
      }
      signal {
        flex: 0 0 auto;
      }
    }

    &__ratings {
      @extend %flex-between;

      margin-top: 3px;

      > span {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 2px 3px;
        text-align: left;
      }
      body.no-rating & {
        display: none;
      }
    }

    &__warning {
      @extend %flex-center;

      text-align: center;
      color: $c-bad;
      flex: 1 1 auto;
    }
  }

  &__score {
    display: block;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  &__actions.btn-rack {
    @extend %box-shadow;

    width: 100%;
    justify-content: stretch;
    border: 0;
    border-radius: 0;

    a {
      flex: 0 0 18%;

      &.relation-button {
        flex: 1 1 auto;
      }
    }
  }

  &__details {
    @extend %flex-between;
    white-space: nowrap;

    &__marks {
      padding: 3px 5px;
      background: $c-bad;
      color: #fff;
    }

    span {
      padding: 0.3em 0.5em 0.3em 0.5em;
    }
  }
}

#miniGame,
#miniBoard {
  min-height: 262px;

  .spinner {
    margin: 82px auto 0 auto;
  }

  cg-board {
    border-radius: 0;
  }
}
