@mixin mq-is-col1 {
  @media (max-width: at-most($small)) and (orientation: portrait) {
    @content;
  }
}
@mixin mq-phone-landscape {
  @media (max-height: at-most($tall)) and (orientation: landscape) {
    @content;
  }
}
@mixin mq-at-least-col2 {
  @media (min-width: at-least($small)), (orientation: landscape) {
    @content;
  }
}
@mixin mq-is-col2-squeeze {
  @media (max-width: at-most($small)) and (orientation: landscape) {
    @content;
  }
}
@mixin mq-at-least-col3 {
  @media (min-width: at-least($x-large)) {
    @content;
  }
}
@mixin mq-site-header-tall {
  @media (min-height: at-least($tall)) {
    @content;
  }
}
@mixin mq-topnav-hidden {
  @media (max-width: at-most($topnav-visible)) {
    @content;
  }
}
@mixin mq-topnav-visible {
  @media (min-width: at-least($topnav-visible)) {
    @content;
  }
}
@mixin mq-topnav-visible-and-tall {
  @media (min-width: at-least($topnav-visible)) and (min-height: at-least($tall)) {
    @content;
  }
}
@mixin mq-sticky-header {
  @media (prefers-reduced-motion: no-preference) and (min-height: $tall) {
    @content;
  }
}
@mixin mq-subnav-top {
  @media (max-width: at-most($small)) {
    @content;
  }
}
@mixin mq-subnav-side {
  @media (min-width: at-least($small)) {
    @content;
  }
}
@mixin mq-zoom-enabled {
  @media (min-width: at-least($small)) and (min-height: at-least($short)) {
    @content;
  }
}
@mixin mq-board-resizable {
  @media (min-width: at-least($small)) {
    @content;
  }
}
@mixin mq-safari {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}
