/* circular loader */
$len1: 33.96;
$len2: 42.6;
$len3: 104.31;
$total_len: $len1 + $len2 + $len3;

@keyframes mask1 {
  0% {
    stroke-dashoffset: $total_len;
  }
  100% {
    stroke-dashoffset: -$total_len;
  }
}

@keyframes mask2 {
  0% {
    stroke-dashoffset: $total_len + $len1;
  }
  100% {
    stroke-dashoffset: -$total_len + $len1;
  }
}

@keyframes mask3 {
  0% {
    stroke-dashoffset: $total_len + $len1 + $len2;
  }
  100% {
    stroke-dashoffset: -$total_len + $len1 + $len2;
  }
}

@keyframes spinner-color {
  0%,
  100% {
    stroke: $c-primary;
  }
  25% {
    stroke: $c-error;
  }
  50% {
    stroke: $c-secondary;
  }
  75% {
    stroke: $c-brag;
  }
}

@keyframes spinner-color {
  0%,
  100% {
    stroke: #42a5f5;
  }
  25% {
    stroke: #f44336;
  }
  50% {
    stroke: #fdd835;
  }
  75% {
    stroke: #4caf50;
  }
}

.spinner {
  width: 70px;
  height: 70px;
  margin: auto;

  path {
    animation: mask1 2.75s cubic-bezier(0.49, 0.67, 0.45, 0.29) infinite;
  }
  path:nth-child(2) {
    animation-name: mask2;
  }
  path:nth-child(3) {
    animation-name: mask3;
  }

  g {
    animation: spinner-color 11s steps(1) infinite !important;
    stroke-dasharray: $total_len $total_len;
  }

  .white & path {
    stroke: #fff;
  }
}

/* small horizontal loader */
@keyframes ddloader {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

.ddloader {
  background: url(../images/loader/whitex1.png) no-repeat;
  @include if-light {
    background: url(../images/loader/blackx1.png) no-repeat;
  }

  animation: ddloader 0.5s steps(15) infinite !important;
  vertical-align: middle;
  display: inline-block;
  width: 32px;
  height: 8px;
}
